<template>
    <div class="view pa24">
        <el-tabs v-model="searchType" @tab-click="searchFun">
            <el-tab-pane :label="row.title" :name="row.id" v-for="(row, index) in tabData" :key="index"></el-tab-pane>
        </el-tabs>
        <div class="d-flex mt20">
            <div>
                <el-input class="w400 mr10" placeholder="请输入模板名称关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
            <div style="margin-left: auto">
                <el-button type="primary" @click="showUploadModel" class="wordBtn">上传Word模板</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="name" align="center" label="模板名称" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <a :href="scope.row.fileUrl" class="cblue ml20" target="_blank">{{ scope.row.name }}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="fileUrl" align="center" label="类型" show-overflow-tooltip width="120">
                    <template slot-scope="scope">
                        {{ typeData[scope.row.modelType] }}
                    </template>
                </el-table-column>
                <el-table-column prop="userName" align="center" label="提交用户" show-overflow-tooltip width="120" />
                <el-table-column prop="createTime" align="center" label="上传时间" show-overflow-tooltip />
                <el-table-column prop="fileUrl" align="center" label="下载" show-overflow-tooltip width="50">
                    <template slot-scope="scope">
                        <a :href="scope.row.fileUrl" class="cblue" target="_blank" title="文档下载" style="color: #3a78ef;"><i
                                class="icondoc iconYQH"></i> </a>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button type="text" @click="updateFun(scope.row)">编辑</el-button>
                        <el-button type="text"
                            @click="showModelFormat(scope.row.modelFormat, 'show', scope.row)">查看格式</el-button>
                        <el-button class="mr10" style="color:#F56C6C" type="text" @click="delWordModel(scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="模板上传" center width="410px"
            :visible.sync="uploadFilesVisible">
            <p class="fs6 mb10">请按照规定好的格式，设计Word文档在上传</p>
            <div>
                文档模板：<a class="cblue ml20" href="#" @click="showModelAsk">模板规范</a>
            </div>
            <div class="mt10">
                模板类型：<el-select class="mb10" v-model="modelType" placeholder="请选择类型" v-if="formType == 'add'">
                    <el-option v-for="(name, id) in typeData" :key="id" :label="name" :value="Number(id)">
                    </el-option>
                </el-select>
            </div>
            <div class="d-flex w320 posre">
                <!-- <el-upload class="mt20 mb20" action="#" accept=".docx" list-type="picture" :auto-upload="false"
                    :show-file-list="false" :on-change="handleUpload" :file-list="fileList">
                    <el-button size="small" type="primary" class="wordBtn w250 text-overflow">{{ fileName }}</el-button>
                </el-upload> -->
                <el-upload class="mt20 mb20" action="#" accept=".docx" list-type="picture" :auto-upload="false"
                    :show-file-list="false" :on-change="handleUpload" :file-list="fileList" drag>
                    <i v-if="analysisBtn" class="eupload el-icon-loading"></i>
                    <i v-else class="el-icon-upload"></i>
                    <div class="el-upload__text">
                        将文件拖到此处，或 <em>点击上传</em>
                        <div>
                            只能上传Word文档
                        </div>
                    </div>
                </el-upload>
            </div>
        </el-dialog>

        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="模板格式" :visible.sync="showModel"
            :destroy-on-close="true" width="80%" center class="posre">
            <div class="uploadBtn flex-c-c" v-if="formType == 'add'" @click="analysisWordFun('upload')" title="点击上传Word模板">
                <i class="el-icon-loading" v-if="uploadBtn"></i>
                <i class="el-icon-upload" v-else></i>
            </div>
            <div class="closeBtn flex-c-c" @click="showModel = false" title="取消">
                <i class="el-icon-close"></i>
            </div>
            <div class="showModelContent">
                <!-- <el-button type="primary" class="wordBtn" @click="analysisWordFun('upload')" :loading="uploadBtn">
                确认上传
            </el-button> -->
                <div class="flex-a-c mb10">模板类型：{{ typeData[modelType] }}</div>
                <div class="btef0"></div>
                <div v-html="modelContent.html">

                </div>
            </div>
            <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" class="wordBtn" @click="analysisWordFun('upload')" :loading="uploadBtn">
                    确认上传
                </el-button>
            </span> -->
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="模板规范"
            :visible.sync="showModelAskVisible" :destroy-on-close="true" width="800px" center>
            <el-image class="w100p"
                src="https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/yimai_photos/iconFont/modelAsk/ask.png"></el-image>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="模板类型" :visible.sync="showUpdateModel"
            :destroy-on-close="true" width="270px" center>
            <div class="mb10">
                模板类型：
            </div>
            <el-select class="mb10" v-model="modelType" placeholder="请选择类型">
                <el-option v-for="(name, id) in typeData" :key="id" :label="name" :value="Number(id)">
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showUpdateModel = false">取 消</el-button>
                <el-button type="primary" @click="setWordModel" :loading="updateBtnLoading">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getWordModelList, delWordModel, setWordModel } from "@/api/projectAdmin";
import axios from 'axios'
export default {
    name: "groupingTable",
    components: {
        commonTable,
    },
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            //导入模板配置
            uploadFilesVisible: false,
            uploadModel: {},
            modelContent: {
                html: " ",
                list: [],
                normal: {}
            },
            fileName: "点击上传",
            fileList: [],
            uploadBtn: false,
            analysisBtn: false,
            formData: {},
            modelDataTable: [
                { title: "-" },
                { title: "-" },
                { title: "-" }
            ],
            showModelAskVisible: false,
            tabData: [
                { id: 'all', title: "全部" },
                { id: '1', title: "立项报告" },
                { id: '2', title: "可行性报告" },
                { id: '3', title: "立项决议" },
                { id: '4', title: "测试报告" },
                { id: '5', title: "结题报告" }
            ],
            typeData: {
                0: "其他", 1: "立项报告", 2: "可行性报告", 3: "立项决议", 4: "测试报告", 5: "结题报告"
            },
            searchType: "all",
            modelType: "",
            formType: "",
            updateId: 0,
            updateBtnLoading: false,
            showUpdateModel: false,
        };
    },
    created() {
        this.getList();
    },
    methods: {
        showUploadModel() {
            this.uploadFilesVisible = true
            this.fileList = [];
            this.formType = "add";
            this.modelType = "";
        },
        showModelAsk() {
            this.showModelAskVisible = true;

        },
        /**@method 删除模板 */
        delWordModel(row) {
            this.$confirm("确定要继续删除这条模板数据", "删除模板", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                let params = {
                    modelId: row.modelId
                }
                delWordModel(params).then(res => {
                    if (res.code === 200) {
                        this.$message.success('删除完成');
                        this.searchData.name = "";
                        this.searchFun();
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    if (err.code === 201) {
                        this.$message.error(err.message);
                    }
                })
            })
        },
        updateFun(row) {
            this.modelType = row.modelType;
            this.updateId = row.modelId;
            this.showUpdateModel = true;
        },
        /**@method 显示word格式 */
        showModelFormat(data, type, row) {
            this.formType = type;
            if (row) {
                this.modelType = row.modelType;
            }
            this.showModel = true;
            if (data) {
                let html = data.html ? data.html.replace(/<textarea/ig, '<textarea readonly') : "";
                html = html.replace(/<table/ig, '<div class="modelTable"><table width="100%"')
                html = html.replace(/<\/table>/ig, '</table></div>')
                this.modelContent = {
                    html: html,
                    list: data.list ? data.list : [],
                    normal: data.normal ? data.normal : {}
                };
            }
        },
        setWordModel() {
            this.updateBtnLoading = true;
            let params = {
                modelId: this.updateId,
                modelType: this.modelType
            };
            setWordModel(params).then(res => {
                if (res.code === 200) {
                    this.$message.success("编辑完成");
                    this.getList();
                    this.updateBtnLoading = false;
                    this.showUpdateModel = false;
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(res.message);
                }
            })
        },
        /**@method 上传模板/解析模板 */
        async analysisWordFun(type) {
            // let params = {
            //     file: this.uploadModel
            // };
            const fromData = new FormData()
            fromData.append('file', this.uploadModel)
            if (type === 'upload') {
                if (this.uploadBtn) {
                    return;
                }
                // params.companyId = this.userInfo.companyId
                // params.userId = this.userInfo.userId
                // params.upload = true;
                fromData.append('companyId', this.userInfo.companyId)
                fromData.append('userId', this.userInfo.userId)
                fromData.append('upload', true)
                fromData.append('modelType', this.modelType)
                this.uploadBtn = true;
            } else {
                this.analysisBtn = true;
            }
            try {
                const res = await axios({
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    method: 'post',
                    url: '/api/crmPc/project/analysisWord',
                    data: fromData,
                })
                if (res.code === 200) {
                    if (type === "upload") {
                        this.$message.success("上传成功");
                        this.fileList = [];
                        this.showModel = false;
                        this.uploadFilesVisible = false;
                        this.searchFun();
                    } else {
                        this.showModelFormat(res.data, 'add');
                    }
                } else {
                    this.$message.error(res.message);
                }
                this.uploadBtn = false;
                this.analysisBtn = false;
            } catch (error) {
                this.uploadBtn = false;
                this.analysisBtn = false;
            }
        },
        handleUpload(file) {
            this.uploadModel = file.raw
            this.fileName = file.name;
            this.analysisWordFun('analysis')
        },
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        /**@method 获取列表 */
        async getList(payload) {
            // searchType
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                name: this.searchData.name
            };
            if (this.searchType !== 'all') {
                params.modelType = this.searchType;
            }
            try {
                this.loading = true;
                let res = await getWordModelList(params)
                this.loading = false;
                const { data } = res;
                let tableData = [];
                for (let row of data.pageInfo.list) {
                    tableData.push({
                        ...row,
                        modelFormat: JSON.parse(row.content)
                    })
                }
                this.tableData = tableData;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
    },
};
</script>

<style lang="scss" scoped>
.eupload.el-icon-loading {
    font-size: 67px;
    color: #c0c4cc;
    margin: 40px 0 16px;
    line-height: 50px;
}

.showModelContent {
    overflow-x: scroll;
    max-width: 842px;
    margin: auto;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    padding: 20px;
}

.uploadBtn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #3a78ef;
    color: #fff;
    font-size: 40px;
    position: fixed;
    top: 150px;
    cursor: pointer;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.50);
}

.closeBtn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #F56C6C;
    color: #fff;
    font-size: 40px;
    position: fixed;
    top: 210px;
    cursor: pointer;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.50);
}

.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>